import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import './CalculatorResponse.css';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import axios from 'axios';
import { getCookie } from '../utils/cookie';
import arrow from '../components/images/middle-arrow.jpg';
import LoadingIcon from '../components/LoadingIcon';


function CalculatorResponse({ score, estiScore, calculatorTimes, CALCULATOR_TIMES }) {
  const { user } = useAuthContext();
  let navigate = useNavigate();
  const handleClick = () => {
    navigate("/subscribe");
  };

  return (
    <div style={{ 
      padding: '40px 20px', 
      maxWidth: '1200px', 
      margin: '0 auto',
      height: 'auto',
      overflow: 'visible'
    }}>
      <div style={{ marginBottom: '40px' }}>
        <h1 style={{ fontSize: '32px', fontWeight: 'bold', marginBottom: '15px' }}>Current Lifespan Estimate</h1>
        <div style={{ color: '#666', fontSize: '16px' }}>
          We estimate your life span and how much you can increase based on scientific research and AI check terminology
        </div>
      </div>

      <div style={{ 
        textAlign: 'center', 
        marginBottom: '40px',
        position: 'relative'
      }}>
        <h2 style={{ 
          fontSize: '48px', 
          fontWeight: 'bold',
          marginBottom: '20px'
        }}>
          {!score ? <LoadingIcon /> : `${parseFloat(Math.min(score, 94)).toFixed(1)} Years`}
        </h2>

        <div style={{ 
          width: '100%', 
          height: '8px', 
          background: '#eee', 
          borderRadius: '4px',
          position: 'relative',
          marginBottom: '30px'
        }}>
          <div style={{ 
            width: `${94}%`, 
            height: '100%', 
            background: '#28a745',
            borderRadius: '4px',
            position: 'absolute',
            top: 0,
            left: 0
          }}/>
          <div style={{ 
            width: `${(score/100) * 100}%`, 
            height: '100%', 
            background: '#007bff',
            borderRadius: '4px',
            position: 'absolute',
            top: 0,
            left: 0
          }}/>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            marginTop: '10px',
            color: '#666',
            position: 'absolute',
            width: '100%',
            left: '0'
          }}>
            <span>0</span>
            <span>25</span>
            <span>50</span>
            <span>75</span>
            <span>100</span>
          </div>
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
          marginBottom: '20px',
          fontSize: '14px',
          color: '#666'
        }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <div style={{ width: '20px', height: '8px', background: '#007bff', borderRadius: '4px' }}></div>
            <span>Current Lifespan</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <div style={{ width: '20px', height: '8px', background: '#28a745', borderRadius: '4px' }}></div>
            <span>Goal Lifespan</span>
          </div>
        </div>
      </div>

      <div style={{ 
        background: '#f8f9fa', 
        padding: '40px', 
        borderRadius: '8px',
        textAlign: 'center',
        marginBottom: '30px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
      }}>
        <div style={{ fontSize: '18px', marginBottom: '20px' }}>We can help you increase it to</div>
        <h2 style={{ 
          fontSize: '48px', 
          fontWeight: 'bold',
          marginBottom: '20px'
        }}>
          {!estiScore ? <LoadingIcon /> : `${parseFloat(Math.min(estiScore, 94)).toFixed(1)} Years`}
        </h2>
        <p style={{ 
          fontSize: '16px', 
          marginBottom: '30px',
          maxWidth: '600px',
          margin: '0 auto 30px'
        }}>
          Click the subscribe button for a more personalized program to get you to your desired health goals
        </p>
        <button
          onClick={handleClick}
          style={{
            background: '#007bff',
            color: 'white',
            border: 'none',
            padding: '12px 40px',
            borderRadius: '4px',
            fontSize: '18px',
            cursor: 'pointer',
            transition: 'background-color 0.2s ease'
          }}
          onMouseOver={(e) => e.currentTarget.style.background = '#0056b3'}
          onMouseOut={(e) => e.currentTarget.style.background = '#007bff'}
        >
          Subscribe
        </button>
      </div>

      {calculatorTimes >= CALCULATOR_TIMES && !user?.isPremium && (
        <div>
          <div style={{ 
            textAlign: 'center', 
            color: '#dc3545',
            padding: '15px',
            fontSize: '16px',
            marginBottom: '10px'
          }}>
            You have used up all {CALCULATOR_TIMES} free attempts to calculate your lifespan
          </div>
          <div style={{
            backgroundColor: '#f8f9fa',
            padding: '20px',
            borderRadius: '8px',
            margin: '20px 0',
            textAlign: 'center'
          }}>
            <p style={{ marginBottom: '15px' }}>
              Premium users have access to a more sophisticated model that will track your data 
              and provide a personalized plan to extend your lifespan.
            </p>
            <button 
              onClick={handleClick}
              style={{
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Upgrade to Premium
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CalculatorResponse;
