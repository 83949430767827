import React, { useState, useEffect, useMemo } from "react"
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"
import Home from "./pages/Home"
import Log from "./pages/MyLog/Log"
import LongevityQuestionnaireResponse from "./pages/FullProfile/archive/LongevityQuestionnaireResponse"
import Calculator from "./pages/Calculator/Calculator"
import CalculatorResponse from "./components/CalculatorResponse"
import About from "./pages/About"
import Plan from "./pages/MyPlan/Plan"
import LongevityQuestionnaire from "./pages/FullProfile/archive/LongevityQuestionnaire"
import SubscriptionPlans from "./pages/SubscriptionPlans"
import { useAuthContext } from "./hooks/useAuthContext"
import InterventionSelection from "./pages/InterventionSelection"
import AccountSettings from "./pages/UserAccount/AccountSettings"
import PaymentHistory from "./pages/UserAccount/PaymentHistory"
import Progress from "./pages/MyProgress/Progress"
import PittSleepQuality from './pages/MyProgress/progress-tests/PittSleepQuality'
import StressScale from './pages/MyProgress/progress-tests/StressScale'
import DigitSpanTest from './pages/MyProgress/progress-tests/DigitSpanTest'
import ReactionTimeTest from './pages/MyProgress/progress-tests/ReactionTimeTest'
import SelfReactionTest from './pages/MyProgress/progress-tests/SelfReactionTest'
import HappinessTest from "./pages/MyProgress/progress-tests/HappinessTest"
import QoLScale from "./pages/MyProgress/progress-tests/QoLScale"
import AppearanceTest from "./pages/MyProgress/progress-tests/AppearanceTest"
import SexTest from "./pages/MyProgress/progress-tests/SexTest"
import BrainFogTest from "./pages/MyProgress/progress-tests/BrainFogTest"
import SF36Test from "./pages/MyProgress/progress-tests/SF36Test"
import MessageArea from "./components/MessageArea";
import ResourcesPage from "./pages/ResourcesPage"
import CognitoSignup from "./pages/Login/CognitoSignup"
import ConfirmSignup from "./pages/Login/ConfirmSignup"
import CognitoSignin from "./pages/Login/CognitoSignin"
import TermsAndConditions from './pages/Login/TermsAndConditions'; 
import ForgotPassword from "./pages/Login/ForgotPassword"
import ResetPassword from "./pages/Login/ResetPassword"
import Organization from "./pages/Organizations/Organization"
import Family from "./pages/Organizations/Family"
import OrgInvitation from "./pages/Organizations/OrgInvitation"
import PaymentUpdate from "./pages/Organizations/PaymentUpdate"
import StripeFormPage from './pages/StripeFormPage'
import 'bootstrap/dist/css/bootstrap.min.css';
// For Google Analytics
import RouteChangeTracker from "./components/RouteChangeTracker/RouteChangeTracker"
import DisablePassword from "./pages/Login/DisablePassword"
import IntakeForm from "./pages/FullProfile/IntakeForm";
import ProtectedRoute from "./components/ProtectedRoute";
import GuestRoute from "./components/GuestRoute";
import DailyProgress from "./pages/MyProgress/DailyProgress";
import TestsProgress from "./pages/MyProgress/TestsProgress";
import Reports from "./pages/MyProgress/Reports";
import PrivacyPolicy from "./pages/PrivacyPolicy"
import DataDeletionInstructions from "./pages/DataDeletionInstructions"

// signout first so that the invited user can singup/signin and accept the invitation
const SignoutFirst = ({ children }) => {
  const location = useLocation();
  const { dispatch } = useAuthContext()
 
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('invite_id') && queryParams.has('orgtype')) {
      window.location.reload();
      dispatch({ type: 'LOGOUT' });
    }
  }, [location.search]);

  return children;
}

function App () {
  const { user } = useAuthContext()
  // const [currUser, setCurrUser] = useState(null) //added
  // const [currPremium, setCurrPremium] = useState(false)
  const [calculatorData, setCalculatorData] = useState(null)
  const [fullProfileData, setFullProfileData] = useState(null)
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);


  // let checkUserData = () => {
  //   const userData = JSON.parse(localStorage.getItem("mysUser"))
  //   if (userData) {
  //     setCurrUser(userData.firstName)
  //     setCurrPremium(userData.isPremium)
  //   }
  // }
  
  // useMemo(() => {
  //   console.log("useMemo called")
  //   checkUserData() //Doesn't want until render is completed
  //   // Using useMemo() with an empty dependencies array will calculate the value only once, on mount.
  // }, [])

  useEffect(() => {
    const handleContextMenu = (e) => {
        e.preventDefault();
    };

    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleContextMenu);

    document.addEventListener('keydown', function (e) {
      // F12
      if (e.key === 'F12') {
          e.preventDefault();
      }
      // Ctrl+Shift+I (DevTools)
      if (e.ctrlKey && e.shiftKey && e.key === 'I') {
          e.preventDefault();
      }
      // Ctrl+Shift+J (Console)
      if (e.ctrlKey && e.shiftKey && e.key === 'J') {
          e.preventDefault();
      }
      // Ctrl+U (View Source)
      if (e.ctrlKey && e.key === 'U') {
          e.preventDefault();
      }
  });

    // Cleanup the event listener on component unmount
    return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
    };
}, []);
  const [paddingBottom, setPaddingBottom] = useState(0);

  useEffect(() => {
      const footerHeight = document.querySelector('.footer')?.offsetHeight || 0;
      setPaddingBottom(footerHeight + 20);
  }, []);

  // if (isLoading){
  //   return <p>Loading...</p>
  // }

  return (
    <div className="App" style={{ paddingBottom: `${paddingBottom}px` }}>
      <div className="main-container">
        <div className="main-content">
          <BrowserRouter>
            <Navbar />
            <body>
              <div className="content">
                <div>
                  <RouteChangeTracker />
                  <Routes>
                    <Route
                      path="/" 
                      element={<Home />}
                      exact/>
                    <Route
                      path="/calculator"
                      element={
                        <ProtectedRoute>
                          <Calculator onCalculatorData={setCalculatorData} />
                        </ProtectedRoute>
                        // currUser ? (
                        //   <Calculator onCalculatorData={setCalculatorData} />
                        // ) : (
                        //   <Navigate to="/signin" />
                        // )
                      }
                      exact
                    />
                    <Route
                      path="/calculator-response"
                      element={
                        <ProtectedRoute>
                          <CalculatorResponse calculatorData={calculatorData} />
                        </ProtectedRoute>
                        // currUser ? <CalculatorResponse calculatorData={calculatorData} /> : <Navigate to="/signin" />
                      }
                      exact
                    />
                    {/* Protected, Paid rotes */}
                    {/* Add premium checks after demo */}
                    <Route
                      path="/log"
                      // element={currUser ? <Log /> : <Navigate to="/signin" />}
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <Log />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/longevity-questionnaire-response"
                      element={
                        // currUser ? <LongevityQuestionnaireResponse fullProfileData={fullProfileData} /> : <Navigate to="/signin" />
                        <ProtectedRoute requirePremium={true}>
                          <LongevityQuestionnaireResponse fullProfileData={fullProfileData} />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/longevity-questionnaire"
                      // element={currUser ? <LongevityQuestionnaire setFullProfileData={setFullProfileData} /> : <Navigate to="/signin" />}
                      // exact
                      element={
                        // currUser ? <IntakeForm /> : <Navigate to="/signin" />
                        <ProtectedRoute requirePremium={true}>
                          <IntakeForm />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/intervention-selection"
                      // element={currUser ? <InterventionSelection /> : <Navigate to="/signin" />}
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <InterventionSelection />
                        </ProtectedRoute>
                      }
                      exact
                    />

                    {/* Changed from && currPremium for testing */}
                    <Route
                        path="/subscribe"
                        // element={<SubscriptionPlans />}
                        element={<SubscriptionPlans />}
                        exact
                    />
                    <Route path="/payment" element={<StripeFormPage />} />
                    <Route
                      path="/myplan"
                      element={
                        // currUser ? <Plan /> : <Navigate to="/signin"></Navigate>
                        <ProtectedRoute requirePremium={true}>
                          <Plan />
                        </ProtectedRoute>
                      }
                      exact
                    />

                    <Route
                      path="/my-account"
                      element={
                        // currUser ? <AccountSettings /> : <Navigate to="/signin"></Navigate>
                        <ProtectedRoute>
                          <AccountSettings />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    
                    <Route
                      path="/PaymentHistory"
                      element={
                        <ProtectedRoute>
                          <PaymentHistory />
                        </ProtectedRoute>
                      }
                      exact
                    />

                    {/* Organizations/Family */}
                    <Route
                      path="/familyplan"
                      element={
                        // user ? <Family /> : <Navigate to="/login"></Navigate>
                        <ProtectedRoute>
                          <Family />
                        </ProtectedRoute>
                      }
                      exact
                    />

                    <Route
                      path="/organizationplan"
                      element={
                        // user ? <Organization /> : <Navigate to="/login"></Navigate>
                        <ProtectedRoute>
                          <Organization />
                        </ProtectedRoute>
                      }
                      exact
                    />

                    <Route
                      path="/org-invitation"
                      element={
                        // user ? <OrgInvitation /> : <Navigate to="/login"></Navigate>
                        <ProtectedRoute>
                          <OrgInvitation />
                        </ProtectedRoute>
                      }
                      exact
                    />

                    <Route
                      path="/payment_update"
                      element={
                        // currUser ? <PaymentUpdate /> : <Navigate to="/"></Navigate>
                        <ProtectedRoute>
                          <PaymentUpdate />
                        </ProtectedRoute>
                      }
                      exact
                    />

                    {/* end organizations/family */}

                    <Route
                      path="/progress"
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <Progress />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/progress/daily"
                      element={
                      <ProtectedRoute requirePremium={true}>
                        <DailyProgress />
                      </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/progress/tests"
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <TestsProgress />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/progress/reports"
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <Reports />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    {/*Progress Test Routes*/}
                    <Route 
                      path="/pittsleeptest"
                      // element={currUser ? <PittSleepQuality /> : <Navigate to="/signin"></Navigate>}
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <PittSleepQuality />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/stresstest"
                      // element={currUser ? <StressScale /> : <Navigate to="/signin"></Navigate>}
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <StressScale />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/digitspantest"
                      // element={currUser ? <DigitSpanTest /> : <Navigate to="/signin"></Navigate>}
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <DigitSpanTest />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/reactiontimetest"
                      // element={currUser ? <ReactionTimeTest /> : <Navigate to="/signin"></Navigate>}
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <ReactionTimeTest />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/selfreactiontest"
                      // element={currUser ? <SelfReactionTest /> : <Navigate to="/signin"></Navigate>}
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <SelfReactionTest />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                    path="/happinesstest"
                    // element={currUser ? <HappinessTest /> : <Navigate to="/signin"></Navigate>}
                    element={
                      <ProtectedRoute requirePremium={true}>
                        <HappinessTest />
                      </ProtectedRoute>
                    }
                    exact
                    />
                    <Route 
                      path="/qoltest" 
                      // element={currUser ? <QoLScale /> : <Navigate to="/signin"></Navigate>}
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <QoLScale />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route 
                      path="/appearancetest" 
                      // element={currUser ? <AppearanceTest /> : <Navigate to="/signin"></Navigate>}
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <AppearanceTest />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/sextest"
                      // element={currUser ? <SexTest /> : <Navigate to="/signin"></Navigate>}
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <SexTest />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/brainfogtest"
                      // element={currUser ? <BrainFogTest /> : <Navigate to="/signin"></Navigate>}
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <BrainFogTest />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route path="/SF36Test" 
                    // element={currUser ? <SF36Test /> : <Navigate to="/signin"></Navigate>} 
                      element={
                        <ProtectedRoute requirePremium={true}>
                          < SF36Test />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route path="/about" element={<About />} exact />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions setHasScrolledToBottom={() => {}} />} />

                    <Route 
                      path="/resources"
                      element={
                        <ProtectedRoute requirePremium={true}>
                          <ResourcesPage />
                        </ProtectedRoute>
                      }
                      exact
                    />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} exact />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} exact />
                    <Route path="/data-deletion-instructions" element={<DataDeletionInstructions/>} exact />


                    <Route
                      path="/signup"
                      // element={
                      //   !currUser ? <CognitoSignup /> : (
                      //     window.location.search === "" ? (
                      //       <Navigate to="/" />
                      //     ) : (
                      //       <SignoutFirst>
                      //         <CognitoSignup />
                      //       </SignoutFirst>
                      //     )
                      //   )
                      // }
                      element={
                          <CognitoSignup />
                      }
                      exact
                    />
                    <Route path="/confirm-signup" element={<ConfirmSignup />} exact />
                    <Route path="/signin"
                      // element={
                      //   !currUser ? <CognitoSignin /> : (
                      //     window.location.search === "" ? (
                      //       <Navigate to="/" />
                      //     ) : (
                      //       <SignoutFirst>
                      //         <CognitoSignin />
                      //       </SignoutFirst>
                      //     )
                      //   )
                      // }
                      element={
                          <CognitoSignin />
                      }
                      exact

                    />
                    <Route
                      path="/forgot-password"
                      // element={!currUser ? <ForgotPassword /> : <Navigate to="/" />}
                      element={
                        // <GuestRoute>
                          <ForgotPassword />
                        // </GuestRoute>
                      }
                      exact
                    />
                    <Route
                      path="/reset-password"
                      // element={!currUser ? <ResetPassword /> : <Navigate to="/" />}
                      element={
                        // <ProtectedRoute>
                          <ResetPassword />
                        // </ProtectedRoute>
                      }
                      exact
                    />
                    <Route
                      path="/disable-password"
                      // element={!currUser ? <DisablePassword /> : <Navigate to="/" />}
                      element={
                        // <GuestRoute>
                          <DisablePassword />
                        // </GuestRoute>
                      }
                      exact
                    />
                    
                  </Routes>
                  {/* {user && <MessageArea />} */}
                  <MessageArea />
                  <Footer />
                </div>
              </div>
            </body>
          </BrowserRouter>
        </div>
      </div>
    </div>

  )
}

export default App