import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import progress from "../components/images/progress.jpeg";
import row1 from "../components/images/row1.jpeg";
import row2 from "../components/images/row2.jpeg";
import row3 from "../components/images/row3.jpeg";
import row4 from "../components/images/row4.jpeg";
import row5 from "../components/images/row5.jpeg";
import row6 from "../components/images/row6.jpeg";
import arrow from "../components/images/barrow.png";
import laptop from "../components/images/laptop.jpeg";
import "./Home.css";
import Chatbot from "../components/Chatbot";
import Nav from "react-bootstrap/Nav";
import { exchangeCodeForTokens } from "./Login/auth";
import { useAuthContext } from "../hooks/useAuthContext"

function Home() {
  const { dispatch } = useAuthContext()
  
  let navigate = useNavigate();
  // Check if user is login, return null if not login, return user if its already login
  // const [currentUser, setCurrentUser] = useState(null)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const [stepButtonDisplay, setStepButtonDisplay] = useState('Join Now')
  const currentUser = JSON.parse(localStorage.getItem('mysUser'));
  console.log('currentUser status: ' + currentUser)
  // useEffect(() => {
  //   if(localStorage.getItem('mysUser')){
  //     setCurrentUser(JSON.parse(localStorage.getItem('mysUser')))    
  //   }
  //   console.log('currentUser status: ' + currentUser)
  // }, [])
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code) {
        console.log("Authorization code detected: ", code);
        navigate(`/?code=${code}`, { replace: true });
    }
}, [location.search, navigate]);

  useEffect(() => {
    console.log("Full location: ", window.location.href);
    console.log("Location search: ", window.location.search);
    const code = queryParams.get('code');
    console.log("code in the URL: " + code);
    if (code) {
        exchangeCodeForTokens(code, dispatch);
    }
}, [queryParams]);

  useEffect(() => {
    if(currentUser === null){
      setStepButtonDisplay('Join Now')
    } else if(currentUser.isPremium){
      setStepButtonDisplay('Build or Check My Profile')
    } else if(!currentUser.isPremium){
      setStepButtonDisplay('Subscribe')
    }
  }, [currentUser])

  // console.log(currentUser)

  function clickStepsButton(){
    // This function would work when user click button in the steps
    // 1. if the user is not login, Button would display 'Join Now' and would redirect user to /signin
    // 2. if the user is login without premium status, button would display 'Subscribe' and would redirect user to /subscribe
    // 3. if the user is login with premium status, button would display 'Build or Check My Profile' and would redirect user to /longevity-questionnaire
    if(currentUser === null){
      // Step 1
      // alert('to signin')
      navigate("/signin")
    } else if(!currentUser.isPremium){
      // Step 2
      // alert('currentUser.isPremium: ' + currentUser.isPremium)
      navigate("/subscribe")
    } else if(currentUser.isPremium){
      // Step3
      // alert('currentUser.isPremium: ' + currentUser.isPremium)
      navigate("/longevity-questionnaire")
    }
    window.location.reload()
  }

  let handleCalculatorReroute = () => {
    console.log("calculator reroute clicked");
  };

  const videoes = [
    {
      url: "https://www.youtube.com/embed/ggLrQ-h4nvU",
      title: "How to Lose Weight the Easy Way",
    },
    {
      url: "https://www.youtube.com/embed/bWKIiZQg1yk",
      title:
        "The Benefits of a Anti-aging Lifestyle",
    },
    {
      url: "https://www.youtube.com/embed/GNcDeVDX93w",
      title: "Living longer and healthier by reducing calories",
    },
    {
      url: "https://www.youtube.com/embed/ieV_uv139Xw",
      title: "Looking younger by doing facial exercises",
    },
    {
      url: "https://www.youtube.com/embed/9V4qoDREHlc",
      title: "Artificial Sweeteners: are they unhealthy",
    },
    {
      url: "https://www.youtube.com/embed/MxfoqIYxxMw",
      title: "How to Reduce Chronic Stress",
    },
  ];

  return (
    <div className="home">
      <section className="home_module">
        <div>
          <div className="home_content">
            <h1 style={{ marginBottom: "30px" }}>Anti-aging Project</h1>
            <p>
              Our goal is to help users improve their biological age in order to
              live <strong>longer healthier</strong> lives.{" "}
            </p>
            <p>
              We're leveraging <strong>machine learning</strong> to tailor a
              customized plan based on a users current health and lifestyle.
              Each plan includes what changes users can make in their lives to
              start living healthier.
            </p>
            <p>
              <strong>
                Our software will make recommendations that include:
              </strong>
            </p>
            <ul>
              <li>Diet (when, what and how much to eat)</li>
              <li>
                Exercise (cardiovascular, resistance/strength training,
                stretching, bone density, balance)
              </li>
              <li>Nutritional supplements</li>
              <li>Stress reduction</li>
              <li>Healthy sleep</li>
              <li>
                Temporary stress-inducing activities like exposure to heat or
                cold
              </li>
            </ul>
            <p>Get started today by trying our life span calculator for free</p>
            <div style={{ textAlign: "center", paddingTop: "20px" }}>
              <a href="/calculator">Life Span Calculator</a>
            </div>
          </div>
        </div>
      </section>
      <section className="home_module">
        <div className="home_content">
          <h1 style={{ marginBottom: "30px", textAlign: "center" }}>
            Plans backed by Science
          </h1>
          <p>
            Chronological age is how long ago you were born.  Biological age is
            how old your body is from a functional perspective. Chronological
            age and biological can often be different.
          </p>
          <p>
            There are those whose physical ages don’t seem to match up with
            their lifestyles. On one hand some senior folks have all the energy
            in the world to do endless physical activity like hiking or playing
            with their grandchildren. On the other hand, there are people who
            are chronologically young but seem tired, run-down and even prone to
            illness.  Those people are aging faster than normal.
          </p>
          <p>
            Our software is based on review of hundreds of scientific articles
            and surveys of hundreds of people’s lifestyles and health outcomes,
            reviews that are continually updating.
          </p>
          <p>
            State-of-the-art data science and machine learning techniques
            customize the results of this research to make personalized
            recommendations. There is no “one-size-fits-all” approach. With 5 
            scientific publications, our technology has been reviewed and 
            validated by the scientific community.
          </p>
          <div>
            <img
              src={progress}
              style={{ width: "100%", objectFit: "contain" }}
              alt="progress"
            />
          </div>
          {/* <div className="progress_image"></div> */}
        </div>
      </section>
      <section className="home_module">
        <div className="home_content">
          <h1 style={{ marginBottom: "30px", textAlign: "center" }}>
            Categories
          </h1>
          <div>
            <div className="irow">
              <img src={row1} alt="nutrition"></img>
              <div>
                <h3>Nutritional Supplements</h3>
                <p>
                  Follow leading advice to find out what supplements could be
                  supplied due to deficiencies in tracked diet.
                </p>
              </div>
            </div>
            <div className="irow">
              <img src={row2} alt="sleep"></img>
              <div>
                <h3>Sleep</h3>
                <p>
                  Sleep is one of the most vital factors to good health. Our
                  model can help regulate and improve sleep.
                </p>
              </div>
            </div>
            <div className="irow">
              <img src={row3} alt="diet"></img>
              <div>
                <h3>Diet</h3>
                <p>
                  Plans include when, how much, and what to eat for different
                  food categories.
                </p>
              </div>
            </div>
            <div className="irow">
              <img src={row4} alt="exercise"></img>
              <div>
                <h3> Exercise</h3>
                <p>
                  Guidance on resistance training, mobility, and cardio will be
                  provided.
                </p>
              </div>
            </div>
            <div className="irow">
              <img src={row5} alt="stress reduction"></img>
              <div>
                <h3> Stress Reduction</h3>
                <p>
                  Methods to reduce stress on a day to day basis to eventually
                  reduce overall and increase stress management.
                </p>
              </div>
            </div>
            <div className="irow">
              <img src={row6} alt="stress induction"></img>
              <div>
                <h3> Stress Induction</h3>
                <p>
                  Guidance on proper stress induction (such as cold showers) are
                  beneficial to be incorporated in your lifestyle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home_module">
        <div className="home_content">
          <h1 style={{ marginBottom: "30px", textAlign: "center" }}>
            Track your progress
          </h1>
          <p
            style={{
              width: "80%",
              textAlign: "center",
              margin: "40px auto 100px auto",
            }}
          >
            Personal logs track your lifestyle and health outcomes and uses data
            analytics to determine what is working and not working for you and
            how to continuously improve your health and anti-aging outcomes.
          </p>
          <div className="sub_module" style={{ width: "80%" }}>
            <div>
              <h4>Progress Log</h4>
              <p>
                Daily or weekly progress for each category from your
                interventions
              </p>
            </div>
            <div>
              <h4>Dashboard Reporting</h4>
              <p>See real time updates of your progress</p>
            </div>
          </div>
        </div>
      </section>
      <section className="home_module">
        <div className="home_content" style={{ width: "75%" }}>
          <h1 style={{ marginBottom: "30px", textAlign: "center" }}>
            Modify your Anti-aging plan
          </h1>
          <div className="sub_module">
            <div style={{ padding: "10px", paddingTop: "20px" }}>
              <div style={{ fontSize: "24px", marginBottom: "30px" }}>
                Sensitivity Report
              </div>
              <p>
                Don’t like your results? Use our sensitivity report option to
                modify your packages and compare your life expectancy results We
                will provide with what we think your changes should be and the
                software lets you adjust your personalized plan to fit your
                lifestyle. It will show you your projected benefits in lifespan
                extension and health of your updated plan.
              </p>
              <p>
                This allows for a higher success rate according to each user’s
                consistency. Most health plans fail due to inconsistencies or
                the user was given too advanced of a plan with modifications
                available. Our sensitivity report is how we address that issue.
              </p>
            </div>
            <div>
              <ul className="steps">
                <li>
                  <div style={{ color: "#FF5964", paddingBottom: "15px" }}>
                    Sleep
                  </div>
                  <div>
                    There are 5 areas of sleep identified to help you feel
                    better and more energized. Incorporating these interventions
                    can lead to longer, more restful sleep improving.
                  </div>
                </li>
                <div
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <img src={arrow} style={{ width: "35%" }} alt="arrow"></img>
                </div>

                <li>Sleep at a defined time every night.</li>
                <li>No exposure to blue light an hour prior to bed.</li>
                <li>Get enough stimulus during the day.</li>
                <li>
                  Wake up at the same time every day including weekends and
                  vacations.
                </li>
                <li>Avoid alcohol before bed.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/*<section className="home_module">
        <div className="home_content" style={{ textAlign: "center" }}>
          <h1 style={{ marginBottom: "30px" }}>Community</h1>
          <p style={{ width: "80%", margin: "0 auto", marginBottom: "20px" }}>
            Engage with like minded individuals to stay engaged and motivated
            Post, share, comment, like, join groups!
          </p>
          <p style={{ marginBottom: "60px" }}>
            Our website includes a social media forum where members can talk and
            exchange tips and success stories and provide each other with
            encouragement and support.
          </p>
          <img src={laptop} style={{ width: "100%" }} alt="laptop"></img>
        </div>
      </section>*/}
      <section className="home_module">
        <div className="home_content">
          <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
            Resource Center
          </h1>
          <p
            style={{
              width: "85%",
              textAlign: "center",
              margin: "0 auto",
              marginBottom: "40px",
            }}
          >
            Provides information and videos on why each intervention works and
            how to carry out recommended activities, including exercise,
            meditation, breathing, relaxation, and yoga videos.
          </p>
          <div className="grid_container">
  {videoes.map((video, index) => (
    <div key={index} className="grid_item">
      <iframe
        width="300"
        height="200"
        src={video.url}
        title={video.title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <div style={{ textAlign: "center" }}>{video.title}</div>
    </div>
  ))}
</div>
        </div>
      </section>
      <section className="home_module">
        <div className="home_content">
          <h1 style={{ marginBottom: "30px", textAlign: "center" }}>STEPS</h1>
          <ol>
            <li style={{ marginBottom: "40px" }}>
              You can start by filling out a registration form and then filling
              out a short questionnaire to get a free estimate of your expected
              lifespan along with an estimate of what our program can do to help
              you live a longer and healthier life.
            </li>
            <li style={{ marginBottom: "40px" }}>
              If you decide to become a member, you can do a full registration
              and intake form, and we will provide you with a personalized
              program. We recommend you show this personalized program to your
              doctor before beginning it and make any adjustments your doctor
              recommends.
            </li>
            <li style={{ marginBottom: "40px" }}>
              Sign up. Membership is just $9.99 per month and there is no
              long-term contract to sign or minimum number of months in the
              membership.
            </li>
            <li style={{ marginBottom: "30px" }}>
              Begin your health journey with help and tracking along the way.
            </li>
          </ol>
          <div style={{ textAlign: "center" }}>
            <button className="join_button" onClick={clickStepsButton}>{stepButtonDisplay}</button>
          </div>
        </div>
      </section>
      <Chatbot />
    </div>
  );
}

export default Home;
