import React, { useEffect, useMemo, useRef } from "react";
import "./Plan.css";
import "./Exercise.css";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { sendData } from "./hooks/Data";
import { useAuthContext } from "../../hooks/useAuthContext";

const Exercise = ({
  currExercise,
  setCurrExercise,
  optExercise,
  setOptExercise,
  newExercise,
  setNewExercise,
  limit,
  setIsNewData,
  isNewData,
  unsubmitValues,
  setUnsubmitValues
}) => {
  const { user } = useAuthContext();
  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
    user = user
      ? JSON.parse(user)
      : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  }, []);

  const fieldToSkip = ['balance', 'bone_density']

  const prevExercise = useRef({ ...currExercise });

  useEffect(() => {
    prevExercise.current = { ...currExercise };
  }, [currExercise]);

  const tooltip = (limit) => (
    <Tooltip id="button-tooltip-2">
      Warning: Greater than the maximum value of {limit}.
    </Tooltip>
  );

  const typingTimer = useRef(null);
  const typingInterval = 2000;

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = Number(value) < 0 ? 0 : Number(value);
    setNewExercise((prevState) => ({ ...prevState, [name]: value }));
    setUnsubmitValues((prevState) => ({ ...prevState, [name]: value}));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setNewExercise((prevState) => ({ ...prevState, [name]: value }));

    sendData({[name]: value}, userId);
    // setUnsubmitValues({});

    typingTimer.current = setTimeout(() => {
      const prevValue = prevExercise.current[name];
      if (value === prevValue) {
        return;
      }
      handleSubmit();
      prevExercise.current = { ...prevExercise.current, [name]: value };
    }, typingInterval);
  };

  const handleFocus = (e) => {
    clearTimeout(typingTimer.current);
  };

  const handleSubmit = () => {
    if (Object.keys(unsubmitValues).length === 0) {
      return;
    }

    console.log("unsubmitValues", unsubmitValues);
    let includeStarItem = false;
    for (let key in unsubmitValues) {
      if (fieldToSkip.includes(key)) {
        includeStarItem = true;
      } else {
        includeStarItem = false;
        break;
      }
    }

    // sendData(unsubmitValues, userId);
    setUnsubmitValues({});

    if (!includeStarItem) {
      setIsNewData(true);
    }
  };

  return (
    <section className="mt-4 bg bg-secondary p-3 bg-opacity-10 border border-secondary border-1 shadow">
      <Form.Text>Exercise</Form.Text>
      <div className="d-flex mb-1">
        {/*Headers */}
        <div className="flex-grow-1 text-start">
          <br />
        </div>
        <div className="inline heading-label" style={{ marginRight: "13px" }}>
          Current
        </div>
        <div className="inline heading-label" style={{ marginRight: "20px" }}>
          Optimal
        </div>
        <div className="inline heading-label" style={{ marginRight: "10px" }}>
          New
        </div>
      </div>

      {/*Cardio */}
      <div className="d-flex mb-2">
        <div className="flex-grow-1 text-start me-1">
          <Form.Label className="label-font cardio-label-low mt-1"></Form.Label>
        </div>
        <div className="form-inline">
          <p className="mx-2 mt-2 exercise-label">Minutes / Week</p>
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              currExercise.cardio_low == null
                ? ""
                : Math.round(currExercise.cardio_low)
            }
            disabled
            onChange={(e) =>
              setCurrExercise({ ...currExercise, cardio_low: e.target.value })
            }
          />
        </div>
        <div className="form-inline position-relative">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              optExercise.cardio_low === null
                ? ""
                : Math.round(optExercise.cardio_low)
            }
            disabled
            onChange={(e) =>
              setOptExercise({ ...optExercise, cardio_low: e.target.value })
            }
          />
          <div className="between-fields-text">or</div>
        </div>
        <div className="form-inline position-relative">
          <OverlayTrigger
            placement="right"
            overlay={tooltip(limit.cardio_low)}
            show={newExercise.cardio_low > limit.cardio_low && !isNewData}
          >
            <input
              type="text"
              className="plan-input text-center"
              required
              disabled={isNewData}
              value={newExercise.cardio_low}
              name="cardio_low"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
          <div className="between-fields-text">and</div>
        </div>
      </div>

      <div className="d-flex mb-2">
        <div className="flex-grow-1 text-start me-1">
          <Form.Label className="label-font cardio-label-medium mt-1"></Form.Label>
        </div>
        <div className="form-inline">
          <p className="mx-2 mt-2 exercise-label">Minutes / Week</p>
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              currExercise.cardio_medium == null
                ? ""
                : Math.round(currExercise.cardio_medium)
            }
            disabled
            onChange={(e) =>
              setCurrExercise({
                ...currExercise,
                cardio_medium: e.target.value,
              })
            }
          />
        </div>
        <div className="form-inline position-relative">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              optExercise.cardio_medium === null
                ? ""
                : Math.round(optExercise.cardio_medium)
            }
            disabled
            onChange={(e) =>
              setOptExercise({ ...optExercise, cardio_medium: e.target.value })
            }
          />
          <div className="between-fields-text">or</div>
        </div>
        <div className="form-inline position-relative">
          <OverlayTrigger
            placement="right"
            overlay={tooltip(limit.cardio_medium)}
            show={newExercise.cardio_medium > limit.cardio_medium && !isNewData}
          >
            <input
              type="text"
              className="plan-input text-center"
              required
              disabled={isNewData}
              value={newExercise.cardio_medium}
              name="cardio_medium"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
          <div className="between-fields-text">and</div>
        </div>
      </div>

      <div className="d-flex mb-2">
        <div className="flex-grow-1 text-start me-1">
          <Form.Label className="label-font cardio-label-high mt-1"></Form.Label>
        </div>
        <div className="form-inline">
          <p className="mx-2 mt-2 exercise-label">Minutes / Week</p>
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              currExercise.cardio_high == null
                ? ""
                : Math.round(currExercise.cardio_high)
            }
            disabled
            onChange={(e) =>
              setCurrExercise({ ...currExercise, cardio_high: e.target.value })
            }
          />
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              optExercise.cardio_high === null
                ? ""
                : Math.round(optExercise.cardio_high)
            }
            disabled
            onChange={(e) =>
              setOptExercise({ ...optExercise, cardio_high: e.target.value })
            }
          />
        </div>
        <div className="form-inline">
          <OverlayTrigger
            placement="right"
            overlay={tooltip(limit.cardio_high)}
            show={newExercise.cardio_high > limit.cardio_high && !isNewData}
          >
            <input
              type="text"
              className="plan-input text-center"
              required
              disabled={isNewData}
              value={newExercise.cardio_high}
              name="cardio_high"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Strength Training*/}
      <div className="d-flex mb-2">
        <div className="flex-grow-1 text-start me-1">
          <Form.Label className="label-font mt-1">
            {" "}
            Strength Training<span style={{ fontSize: '0.7em', verticalAlign: 'super' }}>!</span>
          </Form.Label>
        </div>
        <div className="form-inline">
          <p className="mx-2 mt-2 exercise-label">Minutes / Week</p>
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              currExercise.strength_training == null
                ? ""
                : Math.round(currExercise.strength_training)
            }
            disabled
            onChange={(e) =>
              setCurrExercise({
                ...currExercise,
                strength_training: e.target.value,
              })
            }
          />
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              optExercise.strength_training === null
                ? ""
                : Math.round(optExercise.strength_training)
            }
            disabled
            onChange={(e) =>
              setOptExercise({
                ...optExercise,
                strength_training: e.target.value,
              })
            }
          />
        </div>
        <div className="form-inline">
          <OverlayTrigger
            placement="right"
            overlay={tooltip(limit.strength_training)}
            show={
              newExercise.strength_training > limit.strength_training &&
              !isNewData
            }
          >
            <input
              type="text"
              className="plan-input text-center"
              required
              disabled={isNewData}
              value={newExercise.strength_training}
              name="strength_training"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Balance */}
      <div className="d-flex mb-2">
        <div className="flex-grow-1 text-start me-1">
          <Form.Label className="label-font mt-1">Balance*</Form.Label>
        </div>
        <div className="form-inline">
          <p className="mx-2 mt-2 exercise-label">Times / Week</p>
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              currExercise.balance == null
                ? ""
                : Math.round(currExercise.balance)
            }
            disabled
            onChange={(e) =>
              setCurrExercise({ ...currExercise, balance: e.target.value })
            }
          />
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              optExercise.balance === null
                ? ""
                : Math.round(optExercise.balance)
            }
            disabled
            onChange={(e) =>
              setOptExercise({ ...optExercise, balance: e.target.value })
            }
          />
        </div>
        <div className="form-inline">
          <OverlayTrigger
            placement="right"
            overlay={tooltip(limit.balance)}
            show={newExercise.balance > limit.balance && !isNewData}
          >
            <input
              type="text"
              className="plan-input text-center"
              required
              disabled={isNewData}
              value={newExercise.balance}
              name="balance"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Bone Density */}
      <div className="d-flex mb-2">
        <div className="flex-grow-1 text-start me-1">
          <Form.Label className="label-font mt-1">Bone Density*</Form.Label>
        </div>
        <div className="form-inline">
          <p className="mx-2 mt-2 exercise-label">Times / Week</p>
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              currExercise.bone_density == null
                ? ""
                : Math.round(currExercise.bone_density)
            }
            disabled
            onChange={(e) =>
              setCurrExercise({ ...currExercise, bone_density: e.target.value })
            }
          />
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              optExercise.bone_density === null
                ? ""
                : Math.round(optExercise.bone_density)
            }
            disabled
            onChange={(e) =>
              setOptExercise({ ...optExercise, bone_density: e.target.value })
            }
          />
        </div>
        <div className="form-inline">
          <OverlayTrigger
            placement="right"
            overlay={tooltip(limit.bone_density)}
            show={newExercise.bone_density > limit.bone_density && !isNewData}
          >
            <input
              type="text"
              className="plan-input text-center"
              required
              disabled={isNewData}
              value={newExercise.bone_density}
              name="bone_density"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Stretching*/}
      {/* // removed for unused value */}
      {/* <div className="d-flex mb-2">
                <div className="flex-grow-1 text-start me-1">
                <Form.Label className = "label-font mt-1">Stretching</Form.Label>
                </div>
                <div className="form-inline">
                    <p className = "mx-2 mt-2 exercise-label">Times / Week</p> 
                </div>
                <div className="form-inline">
                    <input type = "text" className="plan-input me-4 text-center" required
                     value = {currExercise.stretching} disabled
                    onChange={(e) => setCurrExercise({ ...currExercise, 'stretching': e.target.value })} />
                </div>
                <div className="form-inline">
                    <input type = "text" className="plan-input me-4 text-center" required
                     value = {optExercise.stretching} disabled
                    onChange={(e) => setOptExercise({ ...optExercise, 'stretching': e.target.value })} />
                </div>
                <div className="form-inline">
                <OverlayTrigger
                    placement="right"
                    overlay={tooltip}
                    show={newExercise.stretching > 7 || newExercise.stretching < 0}
                >
                    <input type = "text" className="plan-input text-center" required
                     value = {newExercise.stretching}
                    onChange={(e) => {
                        setNewExercise({ ...newExercise, 'stretching': e.target.value })
                        sendData({'stretching': e.target.value }, userId);
                    }} />
                </OverlayTrigger>
                </div>
            </div> */}

      {/*Sauna*/}
      <div className="d-flex mb-2">
        <div className="flex-grow-1 text-start me-1">
          <Form.Label className="label-font sauna-frequency-label mt-1"></Form.Label>
        </div>
        <div className="form-inline">
          <p className="mx-2 mt-2 exercise-label">Times / Week</p>
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              currExercise.sauna_frequency == null
                ? ""
                : Math.round(currExercise.sauna_frequency)
            }
            disabled
            onChange={(e) =>
              setCurrExercise({
                ...currExercise,
                sauna_frequency: e.target.value,
              })
            }
          />
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              optExercise.sauna_frequency === null
                ? ""
                : Math.round(optExercise.sauna_frequency)
            }
            disabled
            onChange={(e) =>
              setOptExercise({
                ...optExercise,
                sauna_frequency: e.target.value,
              })
            }
          />
        </div>
        <div className="form-inline">
          <OverlayTrigger
            placement="right"
            overlay={tooltip(limit.sauna_frequency)}
            show={
              newExercise.sauna_frequency > limit.sauna_frequency && !isNewData
            }
          >
            <input
              type="text"
              className="plan-input text-center"
              required
              disabled={isNewData}
              value={newExercise.sauna_frequency}
              name="sauna_frequency"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      <div className="d-flex mb-2">
        <div className="flex-grow-1 text-start me-1">
          <Form.Label className="label-font sauna-duration-label mt-1"></Form.Label>
        </div>
        <div className="form-inline">
          <p className="mx-2 mt-2 exercise-label">Minutes / Session</p>
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              currExercise.sauna_duration == null
                ? ""
                : Math.round(currExercise.sauna_duration)
            }
            disabled
            onChange={(e) =>
              setCurrExercise({
                ...currExercise,
                sauna_duration: e.target.value,
              })
            }
          />
        </div>
        <div className="form-inline">
          <input
            type="text"
            className="plan-input me-4 text-center"
            required
            value={
              optExercise.sauna_duration === null
                ? ""
                : Math.round(optExercise.sauna_duration)
            }
            disabled
            onChange={(e) =>
              setOptExercise({ ...optExercise, sauna_duration: e.target.value })
            }
          />
        </div>
        <div className="form-inline">
          <OverlayTrigger
            placement="right"
            overlay={tooltip(limit.sauna_duration)}
            show={
              newExercise.sauna_duration > limit.sauna_duration && !isNewData
            }
          >
            <input
              type="text"
              className="plan-input text-center"
              required
              disabled={isNewData}
              value={newExercise.sauna_duration}
              name="sauna_duration"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>
    </section>
  );
};

export default Exercise;
