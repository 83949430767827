import React, { useEffect, useState } from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function InputSingle({label, options, disabled, instruction, name, onChange, onChangeUnit, onChangeUnitDefault, value, required=true, error, limit}) {
    // const [error, setError] = useState('');

    if(!options){
        options = [];
    }

    useEffect(() => {
        if (options.length > 1 && onChangeUnitDefault && typeof onChangeUnitDefault === 'function') {
            onChangeUnitDefault(name, options[0]);
        }
    }, [])

    useEffect(() => {
        const handleWheel = (event) => {
            if (document.activeElement.type === "number") {
                document.activeElement.blur();
            }
        };
    
        document.addEventListener("wheel", handleWheel);
    
        return () => {
            document.removeEventListener("wheel", handleWheel);
        };
    }, []);
    

    const handleChange = (event) => {
        if(Number(event.target.value) < 0){
            event.target.value = 0;
        }
        onChange(name, event.target.value);
    };

    const handleUnitChange = (event) => {
        if(event.target.value !== options[0]){
            onChangeUnitDefault(name, options[0])
        }
        onChangeUnit(name, event.target.value);
    };

    const errorMessage = 'Error: please fill out this field, or enter 0.'

  return (
        <div class="col" style={{ maxWidth: '700px', margin: '0 auto', marginBottom: '20px' }}>
            <div class="d-flex justify-content-start align-items-center" style={{ 
                height: '45px',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                fontSize: '16px',
                fontWeight: '500',
                color: '#2c3e50',
                paddingLeft: '10px'
            }}>
                <label style={{ margin: 0 }}>{label}</label>
            </div>
            <div class="input-group">
                <input 
                    type='number' 
                    class="form-control" 
                    style={{ height: '45px' }}
                    disabled={disabled} 
                    onChange={handleChange} 
                    value={value}
                    placeholder={instruction || ''}
                />
                {options.length > 1 ? (
                    <div class="input-group-append">
                        <select class="form-control" style={{
                            height: '100%', 
                            display: 'flex', 
                            alignItems: 'left',
                            borderTopLeftRadius: '0',
                            borderBottomLeftRadius: '0',
                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                            minWidth: '150px',
                            textAlign: 'left'
                        }} onChange={handleUnitChange}>     
                            {options.map((option, index) => (
                                <option value={option} id={index} key={index}>{option}</option>                         
                            ))}
                        </select>
                    </div>
                ) : options.length === 1 ? (
                    <div class="input-group-append">
                        <span class="input-group-text" style={{
                            height: '100%', 
                            display: 'flex', 
                            alignItems: 'left',
                            borderTopLeftRadius: '0',
                            borderBottomLeftRadius: '0',
                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                            minWidth: '150px',
                            textAlign: 'left'
                        }} id="basic-addon2" value={options[0]}>{options[0]}</span>
                    </div>
                ) : null}
            </div>
            {Number(value) > Number(limit) && 
                <p style={{color: 'red', fontSize: '16px', marginBottom: '0'}}>Warning! You have exceeded the maximum limit of {limit}.</p>
            }
            {error && 
                <p style={{color: 'red', fontSize: '16px', marginBottom: '0'}}>{error}</p>
            }
        </div>
  )
}
