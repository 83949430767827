import React, { useMemo, useRef } from 'react';
import './Plan.css';
import { useState, useEffect } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { sendData } from './hooks/Data';
import { useAuthContext } from '../../hooks/useAuthContext';
import { UnitConvert } from '../FullProfile/actions';
import SupplementWarning from '../../components/SupplementWarning';
const Supplements = ({
  currSupplements,
  setCurrSupplements,
  optSupplements,
  setOptSupplements,
  newSupplements,
  setNewSupplements,
  greenTeaUnit,
  oliveOilUnit,
  weightUnit,
  loading,
  setIsNewData,
  isNewData,
  toxicLevel,
  setToxicLevel,
  showModal,
  setShowModal,
  unsubmitValues,
  setUnsubmitValues
}) => {
  const { user } = useAuthContext();
  const userId = useMemo(() => {
    let user = localStorage.getItem('mysUser');
    user = user
      ? JSON.parse(user)
      : { userId: '64ad93a2-a276-43e3-8304-be5fbd587ca1' };
    return user.userId;
  }, []);

  const tooltip = (toxic_level, unit = 'mg') => (
    <Tooltip id='button-tooltip-2'>
      Values by {toxic_level} {unit} are considered toxic. Please correct your
      entries.
    </Tooltip>
  );

  const typingTimer = useRef(null);
  const typingInterval = 2000;
  const [vitaminEUnit, setVitaminEUnit] = useState('mg/day');

  const prevSupplements = useRef({ ...currSupplements });
  const fieldToSkip = ['vitamin_a', 'vitamin_b6', 'vitamin_b12', 'folic_acid',
    'vitamin_c', 'vitamin_d', 'vitamin_k','collagen_peptide', 'iron', 'magnesium',
    'probiotics', 'protein_supplements', 'zinc',];

  useEffect(() => {
    prevSupplements.current = { ...currSupplements };
  }, [currSupplements]);

  const handleChange = (e) => {
    const { type, name, value, checked } = e.target;

    setNewSupplements((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
    setUnsubmitValues((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
  };
  
  const handleBlur = (e) => {
    let { type, name, value, checked } = e.target;
    if (type === 'checkbox' && value >= toxicLevel[name]) {
      setShowModal({ show: true, name: name, value: value });
    }

    setNewSupplements((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
    sendData({[name]: value}, userId);
    // setUnsubmitValues({});

    typingTimer.current = setTimeout(() => {
      const prevValue = prevSupplements.current[name];
      if (value === prevValue) {
        return;
      }
      handleSubmit();
      prevSupplements.current = { ...prevSupplements.current, [name]: type === 'checkbox' ? checked : value };
    }, typingInterval);
  };
  
  const handleFocus = (e) => {
    clearTimeout(typingTimer.current);
  };
  
  const handleSubmit = () => {
    if (Object.keys(unsubmitValues).length === 0) {
      return;
    }

    console.log("unsubmitValues", unsubmitValues);
    let includeStarItem = false;
    for (let key in unsubmitValues) {
      if (fieldToSkip.includes(key)) {
        includeStarItem = true;
      } else {
        includeStarItem = false;
        break;
      }
    }

    // sendData(unsubmitValues, userId);
    setUnsubmitValues({});

    if (!includeStarItem) {
      setIsNewData(true);
    }
  };

  return (
    <section className='mt-4 bg bg-secondary p-3 bg-opacity-10 border border-secondary border-1 shadow'>
      <div className='hover-position'>
        <Form.Text className='hover-supplement'>Supplements</Form.Text>
        <SupplementWarning />
        <div className='hover-supplement-text' style={{ fontSize: '16px' }}>
          Indicate daily supplement intake
        </div>
      </div>
      <div className='d-flex mb-1'>
        {/*Headers */}
        <div className='flex-grow-1 text-start'>
          <br />
        </div>
        <div className='inline heading-label' style={{ marginRight: '13px' }}>
          Current
        </div>
        <div className='inline heading-label' style={{ marginRight: '17px' }}>
          Optimal
        </div>
        <div className='inline heading-label' style={{ marginRight: '1px' }}>
          New
        </div>
      </div>

      {/*Multi-Vitamins */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Multi-Vitamins
          </Form.Label>
        </div>
        <div className='form-inline'>
          <input
            type='checkbox'
            checked={currSupplements.multi_vitamins}
            className='supplement-box supplement-box-margin'
            disabled
            onChange={() => {
              setCurrSupplements({
                ...currSupplements,
                multi_vitamins: !currSupplements.multi_vitamins,
              });
            }}
          />
        </div>
        <div className='form-inline'>
          <input
            type='checkbox'
            checked={optSupplements.multi_vitamins}
            className='supplement-box supplement-box-margin'
            disabled
            onChange={() =>
              setOptSupplements({
                ...optSupplements,
                multi_vitamins: !optSupplements.multi_vitamins,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='checkbox'
            name='multi_vitamins'
            checked={newSupplements.multi_vitamins}
            className='supplement-box me-2'
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
      </div>

      {/*Vitamin A */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Vitamin A*
          </Form.Label>
          <div className='hover-supplement-text'>
            Recommended 600+ ug/day. Do not exceed 3,000 ug/day.
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>ug / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.vitamin_a == null
                ? ''
                : Math.round(currSupplements.vitamin_a)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                vitamin_a: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.vitamin_a == null
                ? ''
                : Math.round(optSupplements.vitamin_a)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                vitamin_a: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.vitamin_a, 'ug')}
            show={
              newSupplements.vitamin_a > toxicLevel.vitamin_a &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.vitamin_a}
              name='vitamin_a'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Vitamin B*/}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Vitamin B6*
          </Form.Label>
          <div className='hover-supplement-text'>Recommended 1.5 mg/day</div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>mg / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.vitamin_b6 == null
                ? ''
                : Math.round(currSupplements.vitamin_b6)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                vitamin_b6: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.vitamin_b6 == null
                ? ''
                : Math.round(optSupplements.vitamin_b6)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                vitamin_b6: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.vitamin_b6, 'mg')}
            show={
              newSupplements.vitamin_b6 > toxicLevel.vitamin_b6 &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.vitamin_b6}
              name='vitamin_b6'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Vitamin B12*
          </Form.Label>
          <div className='hover-supplement-text'>
            Recommended 1.5 ug/day. Do not exceed 2 mg/day.
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>ug / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.vitamin_b12 == null
                ? ''
                : Math.round(currSupplements.vitamin_b12)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                vitamin_b12: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.vitamin_b12 == null
                ? ''
                : Math.round(optSupplements.vitamin_b12)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                vitamin_b12: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.vitamin_b12, 'ug')}
            show={
              newSupplements.vitamin_b12 > toxicLevel.vitamin_b12 &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.vitamin_b12}
              name='vitamin_b12'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Folic Acid*
          </Form.Label>
          <div className='hover-supplement-text'>
            Recommended 200 ug/day. Do not exceed 1000 ug/day.
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>ug / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.folic_acid == null
                ? ''
                : Math.round(currSupplements.folic_acid)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                folic_acid: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.folic_acid == null
                ? ''
                : Math.round(optSupplements.folic_acid)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                folic_acid: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.folic_acid, 'ug')}
            show={
              newSupplements.folic_acid > toxicLevel.folic_acid &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.folic_acid}
              name='folic_acid'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Vitamin C*/}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Vitamin C*
          </Form.Label>
          <div className='hover-supplement-text'>
            Recommended 75-90 mg/day. Do not exceed 2,000 mg/day.
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>mg / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.vitamin_c == null
                ? ''
                : Math.round(currSupplements.vitamin_c)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                vitamin_c: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.vitamin_c == null
                ? ''
                : Math.round(optSupplements.vitamin_c)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                vitamin_c: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.vitamin_c, 'mg')}
            show={
              newSupplements.vitamin_c > toxicLevel.vitamin_c &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.vitamin_c}
              name='vitamin_c'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Vitamin D */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Vitamin D*
          </Form.Label>
          <div className='hover-supplement-text'>
            Recommended 15-20 ug/day. Do not exceed 100 ug/day.
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>ug / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.vitamin_d == null
                ? ''
                : Math.round(currSupplements.vitamin_d)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                vitamin_d: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.vitamin_d == null
                ? ''
                : Math.round(optSupplements.vitamin_d)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                vitamin_d: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.vitamin_d, 'ug')}
            show={
              newSupplements.vitamin_d > toxicLevel.vitamin_d &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.vitamin_d}
              name='vitamin_d'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Vitamin E */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Vitamin E<span style={{ fontSize: '0.7em', verticalAlign: 'super' }}>!</span>
          </Form.Label>
          <div className='hover-supplement-text'>
            Recommended 3-4 mg/day. Do not exceed 540 mg/day.
          </div>
        </div>
        {/* <div className='form-inline'>
          <select
            className='form-control'
            value={vitaminEUnit}
            onChange={handleUnitChange}
          >
            <option value='mg/day'>mg/day</option>
            <option value='IU/day'>IU/day</option>
          </select>
        </div> */}
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>mg / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.vitamin_e == null
                ? ''
                : Math.round(currSupplements.vitamin_e)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                vitamin_e: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            // value={
            //   optSupplements.vitamin_e == null
            //     ? ''
            //     : Math.round(optSupplements.vitamin_e)
            // }
            value={"40"}
            disabled
            // onChange={(e) =>
            //   setOptSupplements({
            //     ...setOptSupplements,
            //     vitamin_e: e.target.value,
            //   })
            // }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.vitamin_e, vitaminEUnit)}
            show={
              newSupplements.vitamin_e > toxicLevel.vitamin_e &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.vitamin_e}
              name='vitamin_e'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Vitamin K */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Vitamin K*
          </Form.Label>
          <div className='hover-supplement-text'>
            Recommended 1 ug per kg of body weight. Do not exceed 1 mg/day.
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>ug / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.vitamin_k == null
                ? ''
                : Math.round(currSupplements.vitamin_k)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                vitamin_k: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.vitamin_k == null
                ? ''
                : Math.round(optSupplements.vitamin_k)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                vitamin_k: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.vitamin_k, 'ug')}
            show={
              newSupplements.vitamin_k > toxicLevel.vitamin_k &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.vitamin_k}
              name='vitamin_k'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Calcium */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Calcium
          </Form.Label>
          <div className='hover-supplement-text'>
            Recommended 1,000-1,200 mg/day. Do not exceed 2,000 mg/day.
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>mg / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.calcium == null
                ? ''
                : Math.round(currSupplements.calcium)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                calcium: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.calcium == null
                ? ''
                : Math.round(optSupplements.calcium)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                calcium: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.calcium, 'mg')}
            show={
              newSupplements.calcium > toxicLevel.calcium &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.calcium}
              name='calcium'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Collagen Peptides */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Collagen Peptides*
          </Form.Label>
        </div>
        <div className='form-inline'>
          <input
            type='checkbox'
            checked={currSupplements.collagen_peptide}
            className='supplement-box supplement-box-margin'
            disabled
            onChange={() =>
              setCurrSupplements({
                ...currSupplements,
                collagen_peptide: !currSupplements.collagen_peptide,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='checkbox'
            checked={optSupplements.collagen_peptide}
            className='supplement-box supplement-box-margin'
            disabled
            onChange={() =>
              setOptSupplements({
                ...optSupplements,
                collagen_peptide: !optSupplements.collagen_peptide,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='checkbox'
            name='collagen_peptide'
            checked={newSupplements.collagen_peptide}
            className='supplement-box me-2'
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
      </div>

      {/* Dietary Fiber*/}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Dietary Fiber
          </Form.Label>
          <div className='hover-supplement-text'>Recommended 25-35 g/day.</div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'> g / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.dietary_fiber == null
                ? ''
                : Math.round(currSupplements.dietary_fiber)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                dietary_fiber: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.dietary_fiber == null
                ? ''
                : Math.round(optSupplements.dietary_fiber)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                dietary_fiber: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.dietary_fiber, 'g')}
            show={
              newSupplements.dietary_fiber > toxicLevel.dietary_fiber &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.dietary_fiber}
              name='dietary_fiber'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Fish Oil/Omega-3 */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Fish Oil/Omega 3<span style={{ fontSize: '0.7em', verticalAlign: 'super' }}>!</span>
          </Form.Label>
          <div className='hover-supplement-text'>Do not exceed 5000 mg/day</div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>mg / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.fish_oil_omega_3 == null
                ? ''
                : Math.round(currSupplements.fish_oil_omega_3)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                fish_oil_omega_3: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.fish_oil_omega_3 == null
                ? ''
                : Math.round(optSupplements.fish_oil_omega_3 * 1000)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                fish_oil_omega_3: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.fish_oil_omega_3, 'mg')}
            show={
              newSupplements.fish_oil_omega_3 > toxicLevel.fish_oil_omega_3 &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.fish_oil_omega_3}
              name='fish_oil_omega_3'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Green Tea */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Green Tea
          </Form.Label>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>{greenTeaUnit} / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.green_tea == null
                ? ''
                : Math.round(currSupplements.green_tea)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                green_tea: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.green_tea == null
                ? ''
                : Math.round(optSupplements.green_tea)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                green_tea: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.green_tea, 'mL')}
            show={
              newSupplements.green_tea > toxicLevel.green_tea &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.green_tea}
              name='green_tea'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Iron */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Iron*
          </Form.Label>
          <div className='hover-supplement-text'>
            Recommended 8-15 mg/day. Do not exceed 20 mg/day.
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>mg / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.iron == null
                ? ''
                : Math.round(currSupplements.iron)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({ ...currSupplements, iron: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.iron == null ? '' : Math.round(optSupplements.iron)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({ ...setOptSupplements, iron: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.iron, 'mg')}
            show={
              newSupplements.iron > toxicLevel.iron &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.iron}
              name='iron'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Magnesium */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Magnesium*
          </Form.Label>
          <div className='hover-supplement-text'>
            Recommended 270-300 mg/day. Do not exceed 400 mg/day.
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>mg / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.magnesium == null
                ? ''
                : Math.round(currSupplements.magnesium)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                magnesium: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.magnesium == null
                ? ''
                : Math.round(optSupplements.magnesium)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                magnesium: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.magnesium, 'mg')}
            show={
              newSupplements.magnesium > toxicLevel.magnesium &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.magnesium}
              name='magnesium'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Olive Oil */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Olive Oil<span style={{ fontSize: '0.7em', verticalAlign: 'super' }}>!</span>
          </Form.Label>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>{oliveOilUnit} / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.olive_oil == null
                ? ''
                : Math.round(currSupplements.olive_oil)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                olive_oil: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.olive_oil == null
                ? ''
                : Math.round(optSupplements.olive_oil)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                olive_oil: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.olive_oil, oliveOilUnit)}
            show={
              newSupplements.olive_oil > toxicLevel.olive_oil &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.olive_oil}
              name='olive_oil'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Probiotics */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Probiotics*
          </Form.Label>
        </div>
        <div className='form-inline'>
          <input
            type='checkbox'
            checked={currSupplements.probiotics}
            className='supplement-box supplement-box-margin'
            disabled
            onChange={() =>
              setCurrSupplements({
                ...currSupplements,
                probiotics: !currSupplements.probiotics,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='checkbox'
            checked={optSupplements.probiotics}
            className='supplement-box supplement-box-margin'
            disabled
            onChange={() =>
              setOptSupplements({
                ...optSupplements,
                probiotics: !optSupplements.probiotics,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='checkbox'
            name='probiotics'
            checked={newSupplements.probiotics}
            className='supplement-box me-2'
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
      </div>

      {/* Protein Supplements */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Protein Supplements*
          </Form.Label>
          <div className='hover-supplement-text'>Recommended 25-50 g/day.</div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>g / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.protein_supplements == null
                ? ''
                : Math.round(currSupplements.protein_supplements)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({
                ...currSupplements,
                protein_supplements: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.protein_supplements == null
                ? ''
                : Math.round(optSupplements.protein_supplements)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({
                ...setOptSupplements,
                protein_supplements: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.protein_supplements, 'g')}
            show={
              newSupplements.protein_supplements >
                toxicLevel.protein_supplements &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.protein_supplements}
              name='protein_supplements'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Zinc */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Zinc*
          </Form.Label>
          <div className='hover-supplement-text'>
            Recommended 8-11 mg/day. Do not exceed 40 mg/day.
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>mg / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              currSupplements.zinc == null
                ? ''
                : Math.round(currSupplements.zinc)
            }
            disabled
            onChange={(e) =>
              setCurrSupplements({ ...currSupplements, zinc: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={
              optSupplements.zinc == null ? '' : Math.round(optSupplements.zinc)
            }
            disabled
            onChange={(e) =>
              setOptSupplements({ ...setOptSupplements, zinc: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(toxicLevel.zinc, 'mg')}
            show={
              newSupplements.zinc > toxicLevel.zinc &&
              !loading &&
              !showModal.show
            }
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              value={newSupplements.zinc}
              name='zinc'
              disabled={loading}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>
    </section>
  );
};

export default Supplements;
