import React from 'react'
import InputMulti from '../../../components/questionnaireElement/InputMulti'
import { useEffect, useState } from 'react'
import { stretchingLabel, boneStrengtheningLabel, balanceActivityLabel, meditationLabel, yogaLabel, activitiesSaunaBathingLabel, coldExposureLabel, lowIntensityLabel, moderateIntensityLabel, highIntensityLabel, cardiovascularSaunaBathingLabel, strengthTrainingLabel } from '../Sections/Lables/MyExerciseLabels'
import { stretchingName, boneStrengtheningName, balanceActivityName, meditationName, yogaName, activitiesSaunaBathingName, coldExposureName, lowIntensityName, moderateIntensityName, highIntensityName, cardiovascularSaunaBathingName, strengthTrainingName } from '../Sections/NameTable/MyExerciseName'
import {daysOption, minsOptions} from '../Sections/OptionsTable/MyExerciseOption'
import { getValueByLabel, getLabelByValue } from '../actions'
import { newExerciseOptionsMap } from './LabelValueMap'
const formDataKey = 'IntakeForm'; 
export default function MyExercise({setHasChanges, dataState, setDataState, errors}) {
  const [cigaretteStatus, setCigaretteStatus] = useState('');
  const [formData, setformData] = useState(sessionStorage.getItem(formDataKey) || {});
  const exerciseNameList = [
    lowIntensityName, moderateIntensityName, highIntensityName, cardiovascularSaunaBathingName, strengthTrainingName
  ]
  useEffect(() => {
    const savedData = sessionStorage.getItem(formDataKey);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      exerciseNameList.forEach((exerciseName, id) => {
        parsedData[exerciseName] = getLabelByValue(newExerciseOptionsMap, parsedData[exerciseName])
      })
      setformData(parsedData);
    }
  }, []);
  const handleInputChange = (name, value) => {
    setHasChanges(true);
    const updatedformData = {
      ...formData,
      [name]: typeof value === 'boolean' || ['dob', 'toBedTime', 'getUpTime'].includes(name) ?
        value : isNaN(parseFloat(value)) ? value : parseFloat(value)
    };
    setformData(updatedformData);
    sessionStorage.setItem(formDataKey, JSON.stringify(updatedformData));
    if (name === 'cigarettesUse') {
      setCigaretteStatus(value);
    }
    let newValue = {...dataState, [name]:parseInt(value)}
    setDataState(newValue)
  };
  return (
          <form>
            <div class='inlineContainer'>
              <small class="form-text">My Exercise</small>
            </div>
            <div class='inlineContainer'>
              <div>How many days per week do you perform the following activities?</div>
            </div>
            <InputMulti inputs={[
              {type: 'Menu', label: stretchingLabel, options: daysOption, name: stretchingName},
              {type: 'Menu', label: boneStrengtheningLabel, options: daysOption, name: boneStrengtheningName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <InputMulti inputs={[
              {type: 'Menu', label: balanceActivityLabel, options: daysOption, name: balanceActivityName},
              {type: 'Menu', label: activitiesSaunaBathingLabel, options: daysOption, name: activitiesSaunaBathingName},
              // {type: 'Menu', label: meditationLabel, options: daysOption, name: meditationName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            {/* <InputMulti inputs={[
              {type: 'Menu', label: yogaLabel, options: daysOption, name: yogaName},
              {type: ''},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            /> */}
            {/* <InputMulti inputs={[
              {type: 'Menu', label: coldExposureLabel, options: daysOption, name: coldExposureName},
              {type: ''},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            /> */}
            <div class='inlineContainer'>
              <div>How many minutes a week do you engage in the following cardiovascular exercises?</div>
            </div>
            <InputMulti inputs={[
              {type: 'InputSingle', label: lowIntensityLabel, name: lowIntensityName},
              {type: 'InputSingle', label: moderateIntensityLabel, name: moderateIntensityName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <InputMulti inputs={[
              {type: 'InputSingle', label: strengthTrainingLabel, name: strengthTrainingName},
              {type: 'InputSingle', label: cardiovascularSaunaBathingLabel, name: cardiovascularSaunaBathingName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <InputMulti inputs={[
              
              {type: 'InputSingle', label: highIntensityLabel, name: highIntensityName},
              {type: ''},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
          </form>
  )
}
