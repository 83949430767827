import React from 'react'

export default function DateInput({label, options, disabled, instruction, name, onChange, value, error, max}) {
    const errorMessage = 'Error: Please fill out this field.';
    if(!options){
        options = [];
    }
    const handleChange = (event) => {
        onChange(name, event.target.value);
    };

    return (
        <div class="col" style={{ maxWidth: '700px', margin: '0 auto', marginBottom: '20px' }}>
            <div class="d-flex justify-content-start align-items-center" style={{ 
                height: '45px',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                fontSize: '16px',
                fontWeight: '500',
                color: '#2c3e50',
                paddingLeft: '10px'
            }}>
                <label style={{ margin: 0 }}>{label}</label>
            </div>
            <div class="input-group">
                <input 
                    type="date" 
                    class="form-control" 
                    style={{ height: '45px' }}
                    placeholder={label}  
                    disabled={disabled} 
                    onChange={handleChange} 
                    value={value} 
                    max={max}
                />
            </div>
            {error && <p style={{color: 'red', fontSize: '16px', marginBottom: '0', marginLeft: '5px'}}>{errorMessage}</p>}
            {instruction &&
                <div class="d-flex justify-content-center">
                    <small class="text-muted">{instruction}</small>
                </div>
            }
        </div>
    )
}
