import React, {useState, useEffect, useMemo} from 'react'
import {Button} from 'react-bootstrap'
import axios from 'axios'
import { Line, Bar } from "react-chartjs-2"
import {Splide, SplideSlide} from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import './Sliderchart.css'

function Sliderchart() {
    const baseUrl = process.env.REACT_APP_baseUrl;
    const baseUrlAWS = `${baseUrl}api/myprogress`

    const [progressData, setProgressData] = useState([])
    const [testData, setTestData] = useState([])
    const [activeButton, setActiveButton] = useState("all")

    const userId = useMemo(() => {
        let user = localStorage.getItem("mysUser");
            user = user
            ? JSON.parse(user)
            : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
        return user.userId;
    })

    // const fetchData = async (value) => {
    //     axios.get(
    //         `${baseUrl}/read-all-progress-by-user/${userId}/${value}`,
    //         {
    //             headers: {
    //                 "Content-Type": "application/json"
    //             }
    //         }
    //     ).then(response => {
    //         setProgressData(response.data.all_progress_responses)
    //     }).catch(error => {
    //         if (error.response && error.response.status === 500) {
    //             return null
    //         } else {
    //             console.error('Error fetching data: ', error)
    //             throw error
    //         }
    //     })
    // }

    const fetchTestData = async (value) => {
        axios.get(
            `${baseUrlAWS}/read-all-test-scores-by-user/${userId}/${value}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then(response => {
            setTestData(response.data.all_scores_responses.sort((a, b) => new Date(a.submission_date) - new Date(b.submission_date)))
        }).catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching data: ', error)
                throw error
            }
        })
    }

    useEffect(() => {
        //fetchData("all")
        fetchTestData("all")
    },[])
    
    const changePeriod = async (value) => {
        setActiveButton(value)
        //fetchData(value)
        fetchTestData(value)
    }

    //Pitt graph 
    const optionsPitt = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Pittsburgh Sleep Quality Index',
                font: {
                    family: 'Montserrat',
                    size: 14
                }
            }
        }
    }
    const dataPitt = {
        labels: testData.filter(data => data.testId === 'pitt').map((data) => data.submission_date.substring(0,10)),
        datasets: [
            {
                label: 'Score',
                data: testData.filter(data => data.testId === 'pitt').map((data) => data.score),
                borderColor: 'rgb(33, 235, 79)',
                backgroundColor: 'rgba(33, 235, 79, 0.5)'
            }
        ]
    }

    //Stress graph 
    const optionsStress = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Perceived Stress Scale',
                font: {
                    family: 'Montserrat',
                    size: 14
                }
            }
        }
    }
    const dataStress = {
        labels: testData.filter(data => data.testId === 'stress').map((data) => data.submission_date.substring(0,10)),
        datasets: [
            {
                label: 'Score',
                data: testData.filter(data => data.testId === 'stress').map((data) => data.score),
                borderColor: 'rgb(33, 235, 79)',
                backgroundColor: 'rgba(33, 235, 79, 0.5)'
            }
        ]
    }

    //Digit graph 
    const optionsDigit = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Digit Memory Test',
                font: {
                    family: 'Montserrat',
                    size: 14
                }
            }
        }
    }
    const dataDigit = {
        labels: testData.filter(data => data.testId === 'digit').map((data) => data.submission_date.substring(0,10)),
        datasets: [
            {
                label: 'Score',
                data: testData.filter(data => data.testId === 'digit').map((data) => data.score),
                borderColor: 'rgb(33, 235, 79)',
                backgroundColor: 'rgba(33, 235, 79, 0.5)'
            }
        ]
    }

    //Reaction Time graph 
    const optionsReactiontime = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Reaction Time Test',
                font: {
                   family: 'Montserrat',
                  size: 14
               }
            }
        }
    }
    const dataReactiontime = {
        labels: testData.filter(data => data.testId === 'reactiontime').map((data) => data.submission_date.substring(0,10)),
        datasets: [
            {
                label: 'Score',
               data: testData.filter(data => data.testId === 'reactiontime').map((data) => data.score),
               borderColor: 'rgb(33, 235, 79)',
              backgroundColor: 'rgba(33, 235, 79, 0.5)'
            }
        ]
    }

    //Self Memory graph 
    const optionsSelfreaction = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Self Memory Test',
                font: {
                    family: 'Montserrat',
                    size: 14
                }
            }
        }
    }
    const dataSelfreaction = {
        labels: testData.filter(data => data.testId === 'selfreaction').map((data) => data.submission_date.substring(0,10)),
        datasets: [
            {
                label: 'Score',
                data: testData.filter(data => data.testId === 'selfreaction').map((data) => data.score),
                borderColor: 'rgb(33, 235, 79)',
                backgroundColor: 'rgba(33, 235, 79, 0.5)'
            }
        ]
    }

    //Oxford Happiness graph 
    const optionsHappiness = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Oxford Happiness Questionaire',
                font: {
                    family: 'Montserrat',
                    size: 14
                }
            }
        }
    }
    const dataHappiness = {
        labels: testData.filter(data => data.testId === 'happiness').map((data) => data.submission_date.substring(0,10)),
        datasets: [
            {
                label: 'Score',
                data: testData.filter(data => data.testId === 'happiness').map((data) => data.score),
                borderColor: 'rgb(33, 235, 79)',
                backgroundColor: 'rgba(33, 235, 79, 0.5)'
            }
        ]
    }

    //Quality of Life graph 
    const optionsQol = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Quality of Life Scale',
                font: {
                    family: 'Montserrat',
                    size: 14
                }
            }
        }
    }
    const dataQol = {
        labels: testData.filter(data => data.testId === 'qol').map((data) => data.submission_date.substring(0,10)),
        datasets: [
            {
                label: 'Score',
                data: testData.filter(data => data.testId === 'qol').map((data) => data.score),
                borderColor: 'rgb(33, 235, 79)',
                backgroundColor: 'rgba(33, 235, 79, 0.5)'
            }
        ]
    }

    //Appearance graph 
    const optionsAppearance = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Appearance Scale',
                font: {
                    family: 'Montserrat',
                    size: 14
                }
            }
        }
    }
    const dataAppearance = {
        labels: testData.filter(data => data.testId === 'appearance').map((data) => data.submission_date.substring(0,10)),
        datasets: [
            {
                label: 'Score',
                data: testData.filter(data => data.testId === 'appearance').map((data) => data.score),
                borderColor: 'rgb(33, 235, 79)',
                backgroundColor: 'rgba(33, 235, 79, 0.5)'
            }
        ]
    }

    //Sex graph 
    const optionsSex = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Sex Questionaire',
                font: {
                    family: 'Montserrat',
                    size: 14
                }
            }
        }
    }
    const dataSex = {
        labels: testData.filter(data => data.testId === 'sex').map((data) => data.submission_date.substring(0,10)),
        datasets: [
            {
                label: 'Score',
                data: testData.filter(data => data.testId === 'sex').map((data) => data.score),
                borderColor: 'rgb(33, 235, 79)',
                backgroundColor: 'rgba(33, 235, 79, 0.5)'
            }
        ]
    }

    //Brain Fog graph 
    const optionsBrainfog = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Brain Fog Scale',
                font: {
                    family: 'Montserrat',
                    size: 14
                }
            }
        }
    }
    const dataBrainfog = {
        labels: testData.filter(data => data.testId === 'brainfog').map((data) => data.submission_date.substring(0,10)),
        datasets: [
            {
                label: 'Score',
                data: testData.filter(data => data.testId === 'brainfog').map((data) => data.score),
                borderColor: 'rgb(33, 235, 79)',
                backgroundColor: 'rgba(33, 235, 79, 0.5)'
            }
        ]
    }

    //SF-36 graph 
    const optionsSF36 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'General Health Inventory',
                font: {
                    family: 'Montserrat',
                    size: 14
                }
            }
        }
    }
    const dataSF36 = {
        labels: testData.filter(data => data.testId === '36item_overall').map((data) => data.submission_date.substring(0,10)),
        datasets: [
            {
                label: 'Score',
                data: testData.filter(data => data.testId === '36item_overall').map((data) => data.score),
                borderColor: 'rgb(33, 235, 79)',
                backgroundColor: 'rgba(33, 235, 79, 0.5)'
            }
        ]
    }

    return (
        <div>
            <div style={{textAlign: "right"}}>
                <Button type="button" className={`OptionButton ${activeButton === "all" && "active"}`} value="all" onClick={e => changePeriod(e.target.value)} variant="dark">All</Button>
                <Button type="button" className={`OptionButton ${activeButton === "2" && "active"}`} value="2" onClick={e => changePeriod(e.target.value)} variant="dark">Last 3 Days</Button>
            </div>
            <Splide options={{
                perPage: 1,
                arrows: true,
                pagination: true,
                drag: 'free',
            }}>
                <SplideSlide>
                    <Line options={optionsPitt} data={dataPitt}/>
                </SplideSlide>
                <SplideSlide>
                    <Line options={optionsStress} data={dataStress}/>
                </SplideSlide>
                <SplideSlide>
                    <Line options={optionsDigit} data={dataDigit}/>
                </SplideSlide>
                <SplideSlide>
                    <Line options={optionsReactiontime} data={dataReactiontime}/>
                </SplideSlide>
                <SplideSlide>
                    <Line options={optionsSelfreaction} data={dataSelfreaction}/>
                </SplideSlide>
                <SplideSlide>
                    <Line options={optionsHappiness} data={dataHappiness}/>
                </SplideSlide>
                <SplideSlide>
                    <Line options={optionsQol} data={dataQol}/>
                </SplideSlide>
                <SplideSlide>
                    <Line options={optionsAppearance} data={dataAppearance}/>
                </SplideSlide>
                <SplideSlide>
                    <Line options={optionsSex} data={dataSex}/>
                </SplideSlide>
                <SplideSlide>
                    <Line options={optionsBrainfog} data={dataBrainfog}/>
                </SplideSlide>
                <SplideSlide>
                    <Line options={optionsSF36} data={dataSF36}/>
                </SplideSlide>
            </Splide>
        </div>
    )
}

export default Sliderchart