import React, { useEffect, useState } from 'react';

export default function CalculatorInputSingle({ label, options, disabled, instruction, name, onChange, onChangeUnit, onChangeUnitDefault, value, currentUnit, conversionFactor, error, limit }) {
    if (!options) {
        options = [];
    }

    // const [inputValue, setInputValue] = useState(value);
    useEffect(() => {
        if (options.length > 1 && onChangeUnitDefault && typeof onChangeUnitDefault === 'function') {
            onChangeUnitDefault(name, currentUnit || options[0]);
        }
    }, [currentUnit]);

    const handleInputChange = (event) => {
        if(Number(event.target.value) < 0){
            event.target.value = 0;
        }
        onChange(name, event.target.value);
    };

    const handleBlur = () => {
        // const convertedValue = conversionFactor && name === 'cardio' ? inputValue * conversionFactor : inputValue;
        // const convertedValue = conversionFactor && name === 'cardio' ? value * conversionFactor : value;
        // onChange(name, convertedValue);
        // onChange(name, value)
    };
    // const handleBlur = () => {
    //     let convertedValue = inputValue;
    //     if (conversionFactor) {
    //         if (name === 'cardio') {
    //             convertedValue = inputValue * conversionFactor;
    //         }
    //     }
    //     onChange(name, convertedValue);
    // };

    const handleUnitChange = (event) => {
        if (event.target.value !== options[0]) {
            onChangeUnitDefault(name, options[0]);
        }
        onChangeUnit(name, event.target.value);
    };
    return (
        <div className="col" style={{ maxWidth: '700px', margin: '0 auto', marginBottom: '20px' }}>
            <div className="d-flex justify-content-start align-items-center" style={{ 
                height: '45px',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                fontSize: '16px',
                fontWeight: '500',
                color: '#2c3e50',
                paddingLeft: '10px'
            }}>
                <label style={{ margin: 0 }}>{label}</label>
            </div>
            <div className="input-group">
                <input 
                    type="number" 
                    className="form-control" 
                    style={{ height: '45px' }}
                    disabled={disabled} 
                    onChange={handleInputChange} 
                    onBlur={handleBlur}
                    value={value} 
                    placeholder={instruction || ''}
                />
                {options.length > 1 ? (
                    <div className="input-group-append">
                        <select className="form-control" style={{
                            height: '100%', 
                            display: 'flex', 
                            alignItems: 'left',
                            borderTopLeftRadius: '0',
                            borderBottomLeftRadius: '0',
                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                            minWidth: '150px'
                        }} onChange={handleUnitChange} value={currentUnit}>
                            {options.map((option, index) => (
                                <option value={option} key={index}>{option}</option>
                            ))}
                        </select>
                    </div>
                ) : options.length === 1 ? (
                    <div className="input-group-append">
                        <span className="input-group-text" style={{
                            height: '100%', 
                            display: 'flex', 
                            alignItems: 'left',
                            borderTopLeftRadius: '0',
                            borderBottomLeftRadius: '0',
                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                            minWidth: '150px',
                            textAlign: 'left'
                        }} id="basic-addon2" value={options[0]}>{options[0]}</span>
                    </div>
                ) : null}
            </div>
            {Number(value) > Number(limit) && 
                <p style={{color: 'red', fontSize: '16px', marginBottom: '0'}}>Warning! You have exceeded the maximum limit of {limit}.</p>
            }
            {error && 
                <p style={{color: 'red', fontSize: '16px', marginBottom: '0'}}>{error}</p>
            }
        </div>
    );
}
