import React, { useState, useEffect } from "react"
import { useNavigate, Link, useLocation } from "react-router-dom"
import jwt from 'jwt-decode'
import axios from "axios"
import { useAuthContext } from "../../hooks/useAuthContext"
import "./LoginSignUp.css"
import { signIn , resendConfirmationCode, updateUserResetStatus, checkUserResetStatus, federatedSignIn, exchangeCodeForTokens} from "./auth"
import "./CognitoUI.css"
import CustomEmailValidation from './CustomEmailValidation';
import { updateUserMessageBody } from "./auth"
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import TermsAndConditions from "./TermsAndConditions";


function CognitoSignin () {

  const { dispatch } = useAuthContext()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordType, setPasswordType] = useState("password")
  const [displayPassword, setDisplayPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [invalidCredentials, setInvalidCredentials] = useState(false)
  const [step, setStep] = useState(1)
  const [trustBrowser, setTrustBrowser] = useState(null)
  const [isVerificationSuccessful, setIsVerificationSuccessful] = useState(false)
  const [loginError, setLoginError] = useState("")
  const [MFAComplete, setMFAComplete] = useState(false)
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [error, setError] = useState("")
  const [userPremium, setUserPremium] = useState(false)
  const [loging, setLoging] = useState(false)
  const [block, setBlock] = useState(false) // add for showing block information
  const [resetStatus, setResetStatus] = useState(false)
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [isUpdatingTimezone, setIsUpdatingTimezone] = useState(false); // Track if updating timezone
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const [isPendingRegister, setIsPendingRegister] = useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);

  

  let navigate = useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const invite_id = queryParams.get('invite_id')
  const orgtype = queryParams.get('orgtype')
  const payment_update = queryParams.get('payment_update')

  const AWSbaseURL = process.env.REACT_APP_baseUrl
  //const AWSbaseURL = process.env.REACT_APP_localBaseUrlAWS
  // Add for localhost test
  // const AWSbaseURL = ""
  let baseURL = `${AWSbaseURL}api/users/`
  console.log("print url: " + baseURL);

  const handleFederatedSignIn = (provider) => {
    if (!hasAgreedToTerms) {
      setSelectedProvider(provider);
      setIsTermsModalOpen(true);
      setIsPendingRegister(false);
      setLoginError("Please accept the terms and conditions before proceeding with federated sign-in.");
    } else {
      federatedSignIn(provider);
    }
  };

  const handleAcceptTerms = async () => {
    if (hasScrolledToBottom) {
      setHasAgreedToTerms(true);
      setIsTermsModalOpen(false);
      if (selectedProvider) {
        federatedSignIn(selectedProvider);
      }
    } else {
      setLoginError("Please scroll to the bottom of the terms and conditions first.");
    }
  };
  const getTrustBrowser = async () => {
    try {
      let trustBrowserStatusUrl = baseURL + "login/trust_browser_or_not/" + email
      let trustBrowserResponse = await axios.get(trustBrowserStatusUrl)
      const trustBrowserStatus = trustBrowserResponse.data.trust_browser
      return trustBrowserStatus
    } catch (error) {
      console.log("error: ", error)
      setLoginError("getTrustBrowser operation failed, Please try again.")
      return false
    }
  }
  const handleScroll = (e) => {
    const atBottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    setHasScrolledToBottom(atBottom);
  };
  const fetchPremiumStatus = async (id) => {
    const response = await fetch(`${AWSbaseURL}api/users/userpremium/${id}`)
    const data = await response.json()
    const premiumVal = data.userPremiumLevel !== 0
    return premiumVal
  }

  const handlePasswordDisplay = async () => {
    setDisplayPassword(!displayPassword)
    setPasswordType(displayPassword ? "password" : "text")
  }
  useEffect(() => {
    const code = queryParams.get('code');
    if (code) {
        exchangeCodeForTokens(code, dispatch);
    }
}, [queryParams]);
  const handleSubmit = async (e) => {
    e.preventDefault()
    if(resetStatus){
      navigate('/disable-password')
    }
    // loging wait display
    setLoging(true)

    // Check user status first
    try{
      const resetStatus = await checkUserResetStatus(email)
      const resetPasswordVal = resetStatus.data.resetPassword
      // console.log('resetStatus ', resetPasswrodVal === 0)
      // If resetPasswrodVal == 1, change resetStatus to true
      if(resetPasswordVal === 3){
        setResetStatus(true)
        return
      }
    } catch(error) {
      console.log('Error happens in resStatus check')
    }


    setLoginError("")
    if (!email || !password) {
        setLoginError("Please complete all fields.");
        setIsLoading(false);
        return;
    }
    //tokens
    try {
      const session = await signIn(email, password);
      localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());
      localStorage.setItem('idToken', session.getIdToken().getJwtToken());
      localStorage.setItem('refreshToken', session.getRefreshToken().getToken());
  
      
      const decodedIdToken = jwt(session.getIdToken().getJwtToken());
  
      const isPremium = await fetchPremiumStatus(decodedIdToken.sub)
      setUserPremium(isPremium); // store premium status in state
      
      if (isPremium) {
        await updateUserMessageBody(decodedIdToken.sub); // Call updateUserMessageBody for premium users
      }

      let cognitoUserObj = {
        userId: decodedIdToken.sub, 
        firstName: decodedIdToken.given_name, 
        email: decodedIdToken.email, 
        isPremium: isPremium,        
      };
      localStorage.setItem("mysUser", JSON.stringify(cognitoUserObj))
      
      // reset reset_password
      updateUserResetStatus(email, -1)
      
      dispatch({ type: 'LOGIN', payload: cognitoUserObj });
  
      setIsLoading(false);
      if (isUpdatingTimezone) {
        navigate('/my-account'); // Redirect to /my-account after successful login if updating timezone
      } else {
        if (invite_id) {
          navigate("/org-invitation" + "?invite_id=" + invite_id + "&orgtype=" + orgtype)
        } else if (queryParams.get('subscribe')) {
          navigate("/subscribe")
        } else if (payment_update) {
          navigate("/payment_update")
        } else {
          navigate('/')
          window.location.reload(); // Refresh the page
        }
      }
    } catch (error) {
      setIsLoading(false); 
      const blockInfo = 'Unable to login because of security reasons.'
      if(blockInfo === error.message){
        setBlock(true)
      }

      // if user attempts received, update reset_password value
      if (error.code === 'NotAuthorizedException') {
        updateUserResetStatus(email, 1)
        const resetStatus = await checkUserResetStatus(email)
        const resetPasswordVal = resetStatus.data.resetPassword
        // console.log('resetStatus ', resetPasswrodVal === 0)
        // If resetPasswrodVal == 1, change resetStatus to true
        if(resetPasswordVal === 3){
          setResetStatus(true)
          return
        }
      }          

      if (error) {
        setLoging(false)
        console.log(error);
        // console.log(error.code);
        // console.log(error.message);
          switch (error.code) {
              // case 'NotAuthorizedException':
              //     setBlock(true)
              //     console.log(error.message)
              //     // alert(block == true)
              //     break
              case 'UserNotFoundException':
                  setLoginError("User does not exist");
                  break;
              case 'NotAuthorizedException':
                const resetStatus = await checkUserResetStatus(email)
                const resetPasswordVal = resetStatus.data.resetPassword
                if(resetPasswordVal === 3){
                  setResetStatus(true)
                } else if (resetPasswordVal === 2){
                  setLoginError(`Please enter the correct username or password. You have last attempt before your account will be locked.`);
                } else {
                  setLoginError(`Please enter the correct username or password. You have ${3 - resetPasswordVal} more attempts before your account will be locked.`);
                }
                break;
              case 'PasswordResetRequiredException':
                setLoginError("Password reset required");
                break;
              case 'UserNotFoundException':
                setLoginError("Account has not been verified");
              case 'UserNotConfirmedException':
                setLoginError("Your Account has not been verified. Please verify your account ");
                sessionStorage.setItem('recentlySignedUpUsername', email);
                setShowVerifyButton(true);
                break;
              default:
                setLoginError("An error occurred. Please try again.");
        }
      }
    }
  }
  const verifyAccount = () => {
    sessionStorage.setItem('recentlySignedUpUsername', email);
    resendConfirmationCode(email)

    console.log(payment_update);
    console.log("/payment_update")
    if (invite_id) {
      navigate("/confirm-signup" + "?invite_id=" + invite_id + "&orgtype=" + orgtype)
    } else if (payment_update) {
      navigate("/payment_update")
    } else {
      navigate('/confirm-signup')
    }
  }
/*
  // Effect to automatically hide the popup after 3 seconds
  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false); // Hide the popup after 3 seconds
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [showPopup]);
*/
  return (
    <div style={{ position: 'relative' }}>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h6>Please login to change timezone.</h6>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
      <div>
        <div className="cognito_login_box">
          <form onSubmit={handleSubmit} >
            <h4 className="cognito_login_title">Login </h4>
            <label className="cognito_login_label">Email:</label>
            <div >
              <CustomEmailValidation
                className="cognito_lable_box"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <label className="cognito_login_label">Password:</label>
            <div>
              <input
                className="cognito_lable_box"
                type={passwordType}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                value={password}
              />
            </div>
            <div>
              <input
                style={{ marginRight: "10px", marginTop: "10px" }}
                type="checkbox"
                onChange={() => {
                  handlePasswordDisplay()
                }}
              />
              {!displayPassword && <span >Show password</span>}
              {displayPassword && <span>Hide password</span>}
            </div>
            <Link to="/forgot-password">Forgot Password</Link>
            <br />
            <Link to="/signup">Register</Link>
            <br />
            <Link
              to="#"
              onClick={() => {
                setShowPopup(true); // Show popup when the link is clicked
                setIsUpdatingTimezone(true); // Set flag to indicate timezone update
              }}
            >
              Change Timezone
            </Link>

            <div>
              {block && <p>Sorry, your account has been blocked due to abnormal activity. <br/>Please contact <a href="mailto:info@myyouthspan.com">info@myyouthspan.com</a> for more information.</p>}
              {!block && loginError ? <p className="cognito_login_error">**{loginError}</p> : <p></p>}
              {isLoading && <p>Loading... Please wait... </p>}
              {!block && loging && !resetStatus && <p>Logging in...</p>}
              {resetStatus && <p>Please reset your password due to security concerns.</p>}
              {invalidCredentials && <p>Please provide a registered email and password.</p>}
            </div>
            {showVerifyButton && (
                <button
                    className="cognito_login_submit"
                    onClick={() => {verifyAccount()}}
                >
                    Verify Account
                </button>
            )}

            <div>
              <button className="cognito_login_submit">{!resetStatus ? `Log in` : `Reset Your Password`}</button>
            </div>
            <br></br>

              <h6 className = "cognito_login_text">OR</h6>
            
              <button className="cognito_login_submit social-login-button" onClick={() => handleFederatedSignIn("Google")}>
              <FcGoogle />
                Sign in with Google
              </button>
              {/* 
              <button className="cognito_login_submit social-login-button" onClick={() => handleFederatedSignIn("Facebook")}>
              <FaFacebook color="#3b5998" />
                Sign in with Facebook
              </button>
              */}
          </form>
        </div>
        {isTermsModalOpen && (
        <div className="modal">
          <div className="modal-content" onScroll={handleScroll}>
            <span
              className="close-button"
              onClick={() => setIsTermsModalOpen(false)}
            >
              &times;
            </span>
            <TermsAndConditions
              setHasScrolledToBottom={setHasScrolledToBottom}
            />
            <button onClick={handleAcceptTerms} disabled={!hasScrolledToBottom}>
              Accept
            </button>
          </div>
        </div>
      )}
      </div >
    </div >
  )
}

export default CognitoSignin

