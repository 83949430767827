import React from 'react'

export default function Menu({label, options, name, onChange, value, error}) {
  const errorMessage = 'Error: Please select from the drop-down menu.'
  const handleChange = (event) => {
    onChange(name, event.target.value);
  };
  return (
    <div class="col" style={{ maxWidth: '700px', margin: '0 auto', marginBottom: '20px' }}>
        <div class="d-flex justify-content-start align-items-center" style={{ 
            height: '45px',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
            fontSize: '16px',
            fontWeight: '500',
            color: '#2c3e50',
            paddingLeft: '10px'
        }}>
            <label style={{ margin: 0 }}>{label}</label>
        </div>
        <select 
            class="form-control" 
            onChange={handleChange} 
            value={value}
            style={{ height: '45px' }}
        >
            <option value="" id='0'></option>
            {options.map((option, index) => (
                <option value={index} id={index + 1}>{option}</option>
            ))}
        </select>
        {error && <p style={{color: 'red', fontSize: '16px', marginBottom: '0', marginLeft: '5px'}}>{errorMessage}</p>}
    </div>
  )
}
