import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ padding: "70px", maxWidth: "800px", margin: "auto" }}>
            <h1>Privacy Policy</h1>
            <p><strong>Mety Technology</strong> ("Company," "we," "us," or "our") is committed to protecting the privacy of its users. This Privacy Policy explains how we collect, use, disclose, and protect personal information collected through our subscription software service (the "Service").</p>

            <h2>1. Information We Collect</h2>
            <h3>1.1. Personal Information</h3>
            <p>When you sign up for our Service, we may collect personal information such as your name, email address, billing information, and other contact details.</p>

            <h3>1.2. Usage Information</h3>
            <p>We may collect information about your use of the Service, including login times, IP addresses, device information, and browser type.</p>

            <h2>2. Use of Information</h2>
            <h3>2.1. Provide and Improve the Service</h3>
            <p>We use the information collected to provide, maintain, and improve the Service, including personalizing your experience and troubleshooting issues.</p>

            <h3>2.2. Communications</h3>
            <p>We may use your contact information to send you service-related communications, such as account updates, invoices, and technical notices.</p>

            <h3>2.3. Marketing</h3>
            <p>With your consent, we may send you promotional emails about new features, products, or special offers. You can opt-out of these communications at any time.</p>

            <h2>3. Data Sharing and Disclosure</h2>
            <h3>3.1. Third-Party Service Providers</h3>
            <p>We may share personal information with third-party service providers who assist us in providing the Service, such as hosting providers, payment processors, and customer support services.</p>

            <h3>3.2. Legal Compliance</h3>
            <p>We may disclose personal information if required by law or in response to valid legal requests, such as subpoenas or court orders.</p>

            <h2>4. Data Security</h2>
            <h3>4.1. Security Measures</h3>
            <p>We implement reasonable security measures to protect the confidentiality and integrity of your personal information, including encryption and access controls.</p>

            <h3>4.2. Data Breach Notification</h3>
            <p>In the event of a data breach that compromises your personal information, we will notify you and relevant authorities as required by law.</p>

            <h2>5. Data Retention</h2>
            <h3>5.1. Retention Period</h3>
            <p>We retain personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>

            <h2>6. Your Rights</h2>
            <h3>6.1. Access and Correction</h3>
            <p>You have the right to access and correct your personal information stored in our systems. You can update your account information or request changes by contacting us.</p>

            <h3>6.2. Data Deletion</h3>
            <p>You can request the deletion of your personal information from our systems. However, please note that we may retain certain information as required by law or for legitimate business purposes.</p>

            <h2>7. Children's Privacy</h2>
            <h3>7.1. Age Restriction</h3>
            <p>The Service is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe that we have inadvertently collected information from a child under 13, please contact us immediately.</p>

            <h2>8. Changes to this Policy</h2>
            <h3>8.1. Policy Updates</h3>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website or sending you a notification.</p>

            <h2>9. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:info@myyouthspan.com">info@myyouthspan.com</a>.</p>
        </div>
    );
};

export default PrivacyPolicy;
