import React from 'react';

const DataDeletionInstructions = () => {
    return (
        <div style={{ padding: "70px", maxWidth: "800px", margin: "auto" }}>
            <h1>Data Deletion Instructions</h1>
            <p>If you wish to delete your data from our platform, please email us at <a href="mailto:info@myyouthspan.com">info@myyouthspan.com</a> with your account details and subject line <strong>“Data Deletion Request”</strong>. </p>
            
            <p>We will process your request and delete your data within <strong>30 days</strong>. You will receive a confirmation email once your data has been successfully deleted from our system.</p>
        </div>
    );
};

export default DataDeletionInstructions;
